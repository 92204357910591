import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../../components/Layout';
import config from '../../../constants/config';
import { TTGForgotPassword } from '@ttg/ui-user-lib';

const Reminder = () => {
	return (
		<Layout style={{ background: '#F5F5F5' }}>
			<div className='mx-auto mt-12'>
				<TTGForgotPassword
					apiEndpoint={config.ttg_url}
					captchaEnabled={config.captcha_enabled}
					captchaKey={config.captcha_site_key}
					footer={
						<Link
							to={'/client/login/'}
							className={'flex flex-col items-center text-secondary font-medium my-6 cursor-pointer'}
						>
							Back to Login
						</Link>
					}
				/>
			</div>
		</Layout>
	);
};

export default Reminder;
